import type { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

export interface State {
  url: string;
  isLogged: boolean;
  account: any;
  nombre: string;
  mail: string;
  titulo: string;
  dimensionDepto: string;
  dimensionCeco: string;
  dimensionProyecto: string;
}

export const key: InjectionKey<Store<State>> = Symbol();

const store = createStore<State>({
  state: {
    url: import.meta.env.VITE_API_TARGET,
    // url: "https://oxxean-solicitudcompra-prd.azurewebsites.net/",
    // url: "https://oxxean-solicitudcompra-test.azurewebsites.net/",
    //url: "https://localhost:44312/",
    isLogged: false,
    account: {},
    nombre: "Invitado",
    mail: "",
    titulo: "Solicitud de Compra",
    dimensionDepto: import.meta.env.VITE_DIMENSION_DEPTO,
    dimensionCeco: import.meta.env.VITE_DIMENSION_CECO,
    dimensionProyecto: import.meta.env.VITE_DIMENSION_PROYECTO,
  },
});

export { store };
